import React from 'react';

import { Wheel } from 'spin-wheel';
import { useEffect, useMemo, useRef, useState } from 'react';

export function useWheel({
  initialProps: _initialProps,
}) {
  const wheelRef = useRef(null);

  const [wheel, setWheel] = useState(null);
  const [initialProps] = useState(_initialProps);

  useEffect(() => {
    if (!wheelRef || wheel) return;
    setWheel(new Wheel(wheelRef.current, initialProps));
  }, [initialProps, wheelRef, wheel]);

  const wheelComponent = useMemo(() => {
    return (
      <div
        ref={wheelRef}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  }, []);

  return {
    wheel,
    wheelComponent,
  };
}
