import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

const ElementCarrousel = ({ themeMode, items, extraSliderOpts }) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const defaultSliderOpts = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    centerMode: true,
    dotsClass: themeMode === 'light' ? 'slick-dots light' : 'slick-dots dark',
    className: 'center',
    adaptativeHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }
    ]
  };

  const sliderOpts = { ...defaultSliderOpts, ...extraSliderOpts };

  return (
    <Box maxWidth={'100%'} data-aos={isMd ? 'fade-left' : 'fade-up'}>
      <Slider {...sliderOpts}>
        {items}
      </Slider>
    </Box>
  );
};

ElementCarrousel.propTypes = {
  items: PropTypes.array.isRequired
};

export default ElementCarrousel;
