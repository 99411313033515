import React from 'react';

import Box from '@mui/material/Box';
import MaterialCard from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';

const Card = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      display={'block'}
      width={'100%'}
      height={'100%'}
      sx={{
        overflow: 'visible',
        textDecoration: 'none',
        transition: 'all .2s ease-in-out',
        '&:hover': {
          transform: `translateY(-${theme.spacing(1)})`,
        },
      }}
    >
      <Box
        component={MaterialCard}
        width={'100%'}
        height={'100%'}
        borderRadius={3}
        display={'flex'}
        flexDirection={'column'}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Card;
