import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Timestamp } from '@firebase/firestore';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import isEmail from 'validator/lib/isEmail';

const LeadForm = ({ onFormSubmission, createFunctionToCall, pricingOption }) => {
  const { t } = useTranslation();

  const [openModal, setModalOpen] = React.useState(false);
  const [modalSelection, setModalSelection] = useState(undefined);

  const handleModalOpen = (item) => { setModalSelection(item); setForm(formInitialState); setValidEmail(true); setModalOpen(true); };
  const handleModalCancel = () => { setModalSelection(undefined); setModalOpen(false); };
  const handleModalSubmit = () => {
    const answers = form;
    answers['choice'] = `${t(`${modalSelection.key}.title`)}`;

    if (pricingOption) {
      answers['period'] = pricingOption;
    } else {
      answers['period'] = 'projeto';
    }

    const formValid = 
      answers.company.length > 0 &&
      answers.name.length > 0 &&
      answers.email.length > 0 && isEmail(answers.email) &&
      answers.phone.length > 0 && matchIsValidTel(answers.phone);

    if (formValid) {
      onFormSubmission(answers);
      handleModalCancel();
    }
  };

  const formInitialState = { company: '', name: '', email: '', from: '', phone: '', date: Timestamp.now()};
  const [form, setForm] = React.useState(formInitialState);

  const [validEmail, setValidEmail] = React.useState(true);
  const handleFormChange = (e) => {
    if (e.target.name === 'email') {
      setValidEmail(isEmail(e.target.value));
    }
    setForm((prevForm) => ({ ...prevForm, [e.target.name]: e.target.value }));
  };

  const handlePhoneChange = (value) => {
    handleFormChange({ target: { value, name: 'phone' } });
  };

  useEffect(() => {
    createFunctionToCall(() => handleModalOpen);
  }, [createFunctionToCall]);

  return (
    <Dialog open={openModal} onClose={handleModalCancel}>
      <DialogTitle>{t('promotions.form.title')}</DialogTitle>
      <DialogContent component="form" onSubmit={(e) => e.preventDefault()} >
        <DialogContentText>
          {t('promotions.form.dialog')}
        </DialogContentText>

        <TextField
          required
          autoFocus
          margin="dense"
          id="company"
          name="company"
          label={t('promotions.form.company')}
          type="text"
          fullWidth
          variant="standard"
          onChange={handleFormChange}
        />
        <TextField
          required
          margin="dense"
          id="name"
          name="name"
          label={t('promotions.form.name')}
          type="text"
          fullWidth
          variant="standard"
          onChange={handleFormChange}
        />
        <TextField
          required
          margin="dense"
          id="email"
          name="email"
          label={t('promotions.form.email')}
          type="email"
          fullWidth
          variant="standard"
          onChange={handleFormChange}
          error={!validEmail}
        />
        <MuiTelInput
          required
          margin="dense"
          id="phone"
          name="phone"
          label={t('promotions.form.whatsapp')}
          value={form.phone}
          error={!matchIsValidTel(form.phone)}
          fullWidth
          variant="standard"
          defaultCountry='BR'
          forceCallingCode
          onChange={handlePhoneChange}
        />
        <TextField
          margin="dense"
          id="from"
          name="from"
          label={t('promotions.form.referral')}
          type="text"
          fullWidth
          variant="standard"
          onChange={handleFormChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalCancel}>{t('promotions.form.cancel')}</Button>
        <Button onClick={handleModalSubmit}>{t('promotions.form.send')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeadForm;
