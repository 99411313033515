import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Card from '../../components/Card';
import TopImageSection from '../../components/TopImageSection';
import FlagIcon from '../../svg/flags';


import jobs from 'constants/jobs';

function JobList({ jobs }) {
  const { t } = useTranslation();

  const listItems = jobs.map(({ job, jobInfo }) =>
    <Grid item key={job} xs={12} sm={6} md={4} zIndex={5}>
      <Card>
        <CardContent>
          <Typography variant='h5' style={{ fontWeight: 700, color: '#32484e', marginBottom: '0.5em'}}>
            {t(`jobs.${job}.title`)}
          </Typography>

          <Typography variant='body1' gutterBottom>
            <b>{t('jobs_view.location')}</b>: {t(`jobs.${job}.location`)}
          </Typography> 

          <Typography variant='body1' gutterBottom>
            <b>{t('jobs_view.language')}</b>: <FlagIcon flag={jobInfo.language}></FlagIcon>
          </Typography>
            
          <Typography variant='body1' className='line-clamp-5' gutterBottom style={{'textAlign' : 'justify'}}>
            <b>{t('jobs_view.description')}</b>: {t(`jobs.${job}.description`)}
          </Typography>
          
          <Link component={RouterLink} to={'/jobs/'+job} sx={{ fontWeight: 600, color: '#32484e' }} underline="none">{t('jobs_view.readmore')}</Link>
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <Box>
      <Grid container spacing={4}>
        {listItems}
      </Grid>
    </Box>
  );
}

function JobListView() {
  const { t } = useTranslation();

  const jobList = Object.keys(jobs).map((key) => ({ job: key, jobInfo: jobs[key] }));

  return (
    <>
      <TopImageSection
        title={t('jobs_view.title')}
        imageURI={'https://images.pexels.com/photos/943096/pexels-photo-943096.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'}
        padding={10}
      ></TopImageSection>

      <Box mb={5}>
        <Container>
          <JobList jobs={jobList} />
        </Container>
      </Box>
    </>
  );
}

export default JobListView;
