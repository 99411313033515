export const pages = [
  {
    title: 'navigation.links',
    pages: [
      {
        title: 'navigation.jobs',
        href: '/jobs'
      },
      {
        title: 'navigation.partners',
        href: '/partners'
      },
      {
        title: 'navigation.privacy',
        href: '/privacy'
      },
      {
        title: 'navigation.status',
        href: 'https://status.dawntech.dev'
      },
      {
        title: 'navigation.client+',
        href: 'https://client.dawntech.dev'
      },
      {},
      {
        title: 'navigation.catalyst',
        href: 'https://appcatalyst.com.br'
      }
    ]
  },
  {
    title: 'navigation.chatbots',
    pages: [
      {
        title: 'navigation.plans',
        href: '/chatbots/plans'
      },
      {
        title: 'navigation.cases',
        href: '/chatbots/cases'
      },
    ]
  }
];
