import React from 'react';
import { Redirect } from 'react-router-dom';

import TopImageSection from 'components/TopImageSection';
import privacies from 'constants/privacies';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

function PrivacyView(props) {
  const { t, i18n } = useTranslation();

  const lang = props.match.params.lang;
  if (lang) {
    const exists = privacies[lang];
    if (exists && i18n.resolvedLanguage !== lang) {
      i18n.changeLanguage(lang, () => {});
    }
  }

  const privacy = privacies[i18n.resolvedLanguage];
  if (!privacy) {
    return <Redirect from='*' to='/' />;
  }

  return (
    <>
      <TopImageSection
        imageURI={privacy.backgroundImage}
        opacity={0}
      ></TopImageSection>

      <Container maxWidth="lg">
        <Box component={Card} borderRadius={3} mt={-24} mb={6} px={6} position='relative' zIndex={2} data-aos="fade-in">
          <CardContent>
            <Typography variant="h3" fontWeight='600' marginTop='0.5em' gutterBottom>
              {t('privacy.title')}
            </Typography>

            <Typography variant="body1" textAlign='justify' whiteSpace='pre-wrap'>
              {t('privacy.description')}
            </Typography>

            <Link href={t('privacy.documentURI')}>
              {t('privacy.title')}
            </Link>
          </CardContent>

          <CardContent>
            <Typography variant="overline">
              {t('privacy.details')}
            </Typography>
          </CardContent>
        </Box>
      </Container>
    </>
  );
}

export default PrivacyView;
