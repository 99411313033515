import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import TopImageSection from 'components/TopImageSection';
import Container from 'common/Container';

const partners = [
  {
    partner: 'blip',
    link: 'https://www.blip.ai',
    media: require('../../assets/img/partners/blip-media.png'),
    logo: require('../../assets/img/partners/blip-logo.png')
  },
  {
    partner: 'pipefy',
    link: 'https://www.pipefy.com',
    media: require('../../assets/img/partners/pipefy-media.png'),
    logo: require('../../assets/img/partners/pipefy-logo.png')
  },
  {
    partner: 'tinyerp',
    link: 'https://tiny.com.br',
    media: require('../../assets/img/partners/tinyerp-media.png'),
    logo: require('../../assets/img/partners/tinyerp-logo.png')
  },
  {
    partner: 'bling',
    link: 'https://www.bling.com.br/',
    media: require('../../assets/img/partners/bling-media.png'),
    logo: require('../../assets/img/partners/bling-logo.png')
  },
  {
    partner: 'freshworks',
    link: 'https://www.freshworks.com/',
    media: require('../../assets/img/partners/freshworks-media.png'),
    logo: require('../../assets/img/partners/freshworks-logo.png')
  }
];

function PartnerStories({ stories, themeMode }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      { stories.map((item, i) => (
        <Grid item xs={12} sm={6} md={4} key={i}>
          <Box
            display={'block'}
            width={'100%'}
            height={'100%'}
            sx={{
              textDecoration: 'none',
              transition: 'all .2s ease-in-out',
              '&:hover': {
                transform: `translateY(-${theme.spacing(1 / 2)})`,
              },
            }}
          >
            <Box
              component={Card}
              width={'100%'}
              height={'100%'}
              borderRadius={3}
              display={'flex'}
              flexDirection={'column'}
            >
              <CardMedia
                image={item.media}
                title={t(`partners.stories.list.${item.partner}.name`)}
                sx={{
                  height: 240,
                }}
              />
              <Box component={CardContent}>
                <Box maxWidth={100} marginY={2}>
                  { !item.logo ? <></> :
                    <Box
                      component="img"
                      height={'100%'}
                      width={'100%'}
                      src={item.logo}
                      alt="..."
                      sx={{
                        filter: themeMode === 'dark' ? 'grayscale(1)' : 'none',
                      }}
                    />
                  }
                </Box>

                <Typography
                  align={'left'}
                  variant={'body2'}
                  color="textSecondary"
                >
                  { t(`partners.stories.list.${item.partner}.description`) }
                </Typography>
              </Box>
              <Box flexGrow={1} />
              { !item.link ? <></> : 
                <Box component={CardActions} justifyContent={'flex-start'}>
                  <Button
                    component={'a'}
                    href={item.link}
                    target={'_blank'}
                    size="large"
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                  >
                    { t('partners.stories.action') }
                  </Button>
                </Box>
              }
            </Box>
          </Box>
        </Grid>
      ))}
    </>
  );
}

export default function PartnersView({ themeMode }) {
  const { t } = useTranslation();

  return (
    <>
      <TopImageSection
        imageURI={require('../../assets/img/partners/header.jpg')}
        padding={25}
        opacity={0.2}
        title={t('partners.title')}
      ></TopImageSection>

      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'medium',
              }}
              gutterBottom
              color={'secondary'}
              align={'center'}
            >
              { t('partners.stories.title') }
            </Typography>
            <Box
              component={Typography}
              fontWeight={700}
              variant={'h3'}
              align={'center'}
              sx={{
                whiteSpace: 'pre-line'
              }}
            >
              { t('partners.stories.subtitle') }
            </Box>
          </Box>

          <Grid container spacing={4}>
            <PartnerStories stories={partners} themeMode={themeMode} />
          </Grid>
        </Box>
      </Container>
    </>
  );
}
