import useMetaTags from 'react-metatags-hook';

export default ({ title, description, url }, extraConfig) => {
  if (!extraConfig.openGraph) {
    extraConfig.openGraph = {};
  }

  if (!extraConfig.twitter) {
    extraConfig.twitter = {};
  }

  const openGraph = ((og) => ({
    title: og.title ? og.title : 'Dawntech',
    image: og.image ? og.image : 'http://dawntech.dev/public/icon.png',
    description: og.description ? og.description : description,
    site_name: 'dawntech.dev'
  }))(extraConfig.openGraph);

  const twitter = ((twitter) => ({
    card: 'summary',
    title,
    site: '@dawntech_dev',
    creator: twitter.creator ? twitter.creator : '@dawntech_dev',
  }))(extraConfig.twitter);

  useMetaTags({
    title,
    description,
    charset: 'utf8',
    lang: 'en',
    metas: [
      // { name: 'keywords', content: 'a, list, of, keywords' },
      { name: 'robots', content: 'index, follow' },
      // { name: 'DC.Title', content: 'Dublin Core Title' },
      { name: 'url', content: `https://dawntech.dev/${url}` }
    ],
    openGraph,
    twitter
  });
};
