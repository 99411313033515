const jobs = {
  'dev-backend': {
    linkedin: 'https://www.linkedin.com/company/dawntech',
    banner: 'https://images.pexels.com/photos/177598/pexels-photo-177598.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=2',
    language: 'pt'
  },
  'dev-frontend': {
    linkedin: 'https://www.linkedin.com/company/dawntech',
    banner: 'https://images.pexels.com/photos/177598/pexels-photo-177598.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=2',
    language: 'pt'
  },
  'adm-assistente': {
    linkedin: 'https://www.linkedin.com/company/dawntech',
    banner: 'https://images.pexels.com/photos/196644/pexels-photo-196644.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=1920&w=1080',
    language: 'pt'
  },
  'rh-analista': {
    linkedin: 'https://www.linkedin.com/company/dawntech',
    banner: 'https://images.pexels.com/photos/3184639/pexels-photo-3184639.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=2',
    language: 'pt'
  },
  'chatbot-analista': {
    linkedin: 'https://www.linkedin.com/company/dawntech',
    banner: 'https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&w=1920&h=1080&dpr=2',
    language: 'pt'
  }
};

export default jobs;
