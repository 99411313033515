import React from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import SellIcon from '@mui/icons-material/Sell';
import EmailIcon from '@mui/icons-material/Email';
import UpdateIcon from '@mui/icons-material/Update';
import HelpIcon from '@mui/icons-material/Help';

import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Avatar, CardActionArea, CardMedia, Link } from '@mui/material';

import { HeaderModal } from '../../components/Modal';
import Slider from 'react-slick';

export default function ProductList({ products, themeMode }) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  
  const [openProduct, setProductOpen] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState();

  const [productImages, setSelectedProductImages] = React.useState([]);

  const handleProductOpen = (s) => { setProductOpen(true); setSelectedProduct(s); };
  const handleProductClose = () => setProductOpen(false);

  const { id } = useParams();

  React.useEffect(() => {
    if (id) {
      const item = products.find(item => item.id === id);

      if (item) {
        handleProductOpen(item);
      }
    }
  }, []);

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const sliderOptions = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isSm ? 2 : 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: isMd ? true : false,
    className: themeMode === 'light' ? 'center light' : 'center dark',
    dotsClass: themeMode === 'light' ? 'slick-dots light' : 'slick-dots dark'
  };

  React.useEffect(() => {
    setSelectedProductImages(selectedProduct ? selectedProduct.photos : []);
  }, [selectedProduct]);

  return <>
    {products.map((item, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Box
          display={'block'}
          width={'100%'}
          height={'100%'}
          sx={{
            textDecoration: 'none',
            transition: 'all .2s ease-in-out',
            '&:hover': {
              transform: `translateY(-${theme.spacing(1 / 2)})`,
            },
          }}
        >
          <Card>
            <CardHeader
              avatar={<Avatar sx={{ bgcolor: 'transparent' }} src={item.logo} />}
              title={t(`products.items.${item.id}.title`)}
              subheader={i18n.exists(`products.items.${item.id}.subtitle`) ? t(`products.items.${item.id}.subtitle`) : undefined}
            />
            <Box component={CardContent} py={0}>
              <Box>
                <Typography variant='body2'>
                  {t(`products.items.${item.id}.description`)}
                </Typography>
              </Box>

              {
                !i18n.exists(`products.items.${item.id}.subtitle`) ?
                  <></> :
                  <Box>
                    <Typography variant='body2'>
                      {t(`products.items.${item.id}.subtitle`)}
                    </Typography>
                  </Box>
              }

              {
                !i18n.exists(`products.items.${item.id}.pricing`) ?
                  <></> :
                  <Box>
                    <Typography variant='body2'>
                      {t(`products.items.${item.id}.pricing`)}
                    </Typography>
                  </Box>
              }
            </Box>
            <Box component={CardActions} alignItems={'flex-start'} textAlign={'center'}>
              <Button
                size="large"
                endIcon={
                  <svg
                    width={16}
                    height={16}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                }
                onClick={() => handleProductOpen(item)}
              >
                {t('products.page.open-modal-button')}
              </Button>
            </Box>
          </Card>
        </Box>
      </Grid>
    ))}

    { !selectedProduct ? <></> :
      <HeaderModal open={openProduct} onClose={handleProductClose}
        Header={
          <Box>
            <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
              <Grid item xs sx={{ textAlign: '-webkit-center' }}>
                <Avatar src={selectedProduct.logo} sx={{ width: 90, height: 90 }} variant='rounded' />
              </Grid>

              <Grid item xs={9} sx={{ textAlign: 'center' }}>
                <Typography variant='h5'>
                  {t(`products.items.${selectedProduct.id}.title`)}
                </Typography>

                {
                  i18n.exists(`products.items.${selectedProduct.id}.subtitle`) ?
                    <Typography variant='subtitle1'>
                      { t(`products.items.${selectedProduct.id}.subtitle`) }
                    </Typography>
                    :
                    <></>
                }

                {
                  i18n.exists(`products.items.${selectedProduct.id}.description`) ?
                    <Typography variant='subtitle2'>
                      {t(`products.items.${selectedProduct.id}.description`)}
                    </Typography>
                    :
                    <></>
                }

                {
                  i18n.exists(`products.items.${selectedProduct.id}.pricing`) ?
                    <Typography variant='overline'>
                      {t(`products.items.${selectedProduct.id}.pricing`)}
                    </Typography>
                    :
                    <></>
                }
              </Grid>

              <Grid item xs sx={{ textAlign: 'center' }}>
                <Button size='large' variant='contained' component={Link} href={t(`products.items.${selectedProduct.id}.action-link`)} target='_blank'>
                  {t('products.modal.action-button')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        }
      
        Body={
          <Grid container>
            <Grid item xs={12} sx={{ paddingBottom: 4 }}>
              <Slider {...sliderOptions}>
                {
                  productImages.map((item, i) => (
                    <Box padding={{ xs: 1, sm: 2, height: 50 }} key={i}>
                      <Box
                        component={Card}
                        boxShadow={{ xs: 1, sm: 3 }}
                        borderRadius={5}                      
                      >
                        <CardActionArea href={item.link} target="_blank">
                          <CardMedia component="img" src={item.src} sx={{ aspectRatio: '16/9', height: '260px' }}/>
                        </CardActionArea>
                      </Box>
                    </Box>
                  ))
                }
              </Slider>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Typography variant="h5" pl={2}>{t('products.modal.about')}</Typography>

              {
                i18n.exists(`products.items.${selectedProduct.id}.about`) ?
                  <Box px={2} textAlign={'justify'}>
                    {
                      t(`products.items.${selectedProduct.id}.about`, { returnObjects: true }).map((item, j) => (
                        <Box key={j} py={1} >
                          { !item.paragraph ? <></> : <Typography variant="body2">{item.paragraph}</Typography> }

                          { !item.header ? <></> : <Typography variant="h5">{item.header}</Typography> }

                          {
                            !item.items ? <></> :
                              <List sx={{ listStyleType: 'disc', paddingLeft: 4, paddingY: 0  }}>
                                {
                                  item.items.map((itemText, k) => (
                                    <ListItem sx={{ display: 'list-item', textAlign: 'justify' }} key={k} dense={true}>
                                      {itemText}
                                    </ListItem>
                                  ))
                                }
                              </List>
                          }
                        </Box>
                      ))
                    }
                  </Box>
                  : <></>
              }
            </Grid>

            <Grid item xs={12} sm={4}>
              <Stack direction="row" alignItems="center" gap={1} mb={6}>
                <AttachFileIcon />
                <Typography variant="body1">{t('products.modal.information')}</Typography>
              </Stack>

              {
                i18n.exists(`products.items.${selectedProduct.id}.tags`) ?
                  <Box my={4} px={2}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <SellIcon />

                      {
                        t(`products.items.${selectedProduct.id}.tags`, { returnObjects: true }).map((tag, t) => (
                          <Chip label={tag} key={t} />
                        ))
                      }
                    </Stack>
                  </Box>
                  : <></>
              }

              { 
                i18n.exists(`products.items.${selectedProduct.id}.support`) ?
                  <Box my={4} px={2}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <EmailIcon />

                      <Stack>
                        <Typography variant="body1">{t('products.modal.support')}</Typography>
                        <Typography variant="body2">
                          {t(`products.items.${selectedProduct.id}.support`)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  : <></>
              }

              {
                i18n.exists(`products.items.${selectedProduct.id}.version`) ?
                  <Box my={4} px={2}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <UpdateIcon />

                      <Stack>
                        <Typography variant="body1">
                          {t('products.modal.version')} {t(`products.items.${selectedProduct.id}.version`)}
                        </Typography>
                        <Typography variant="body2">
                          {t('products.modal.last-updated')} {t(`products.items.${selectedProduct.id}.last-updated`)}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  : <></>
              }

              {
                i18n.exists(`products.items.${selectedProduct.id}.documentation`) ?
                  <Box my={4} px={2}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <HelpIcon />

                      <Stack>
                        <Typography variant="body2" component={Link} href={t(`products.items.${selectedProduct.id}.documentation`)} target={'_blank'}>
                          {t('products.modal.documentation')}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  : <></>
              }
            </Grid>
          </Grid>
        }
      />
    }
  </>;
}
