import React from 'react';

import { makeStyles } from '@mui/styles';
import cx from 'clsx';

const useStyles = makeStyles((theme) => ({
  smartphone: {
    position: 'relative',
    width: '340px',
    height: '735px',
    [theme.breakpoints.down('md')]: {
      height: 'auto'
    },
    margin: 'auto',
    border: '8px black solid',
    borderTopWidth: '10px',
    borderBottomWidth: '10px',
    borderRadius: '36px',
    overflow: 'hidden',

    '&.sticky': {
      position: 'sticky',
      top: 0
    },
  
    '&::before': {
      content: '""',
      display: 'block',
      width: '100px',
      height: '35px',
      position: 'absolute',
      top: '0',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: 'black',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px'
    },

    // '&::after': {
    //   content: '""',
    //   display: 'block',
    //   width: '35px',
    //   height: '35px',
    //   position: 'absolute',
    //   left: '50%',
    //   bottom: '-65px',
    //   transform: 'translate(-50%, -50%)',
    //   background: '#333',
    //   borderRadius: '50%'
    // },
    
    '& .content': {
      height: '100%',
      background: 'white'
    },

    '& .content iframe': {
      pointerEvents: 'none'
    },

    '& iframe, & img': {
      width: '100%',
      border: 'none',
      height: '100%'
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      maxHeight: '80%'
    }
  }
}));


export default function PhoneFrame({ image, sticky }) {
  const Styles = useStyles({ sticky });

  const [type, src] = image;

  let Frame;
  switch (type) {
    case 'iframe':
      Frame = <iframe src={src} className="multiple"></iframe>;
      break;
    case 'image':
      Frame = <img src={src}></img>;
      break;
    default:
      throw new Error('Invalid type for phone frame. Use either "iframe" or "image"');
  }

  const [currentFrame] = React.useState(Frame);

  return (
    <div className={`${cx(Styles.smartphone)} ${sticky ? 'sticky' : ''}`}>
      <div className="content">
        {currentFrame}
      </div>
    </div>
  );
}
