import React from 'react';
import { useLocation  } from 'react-router-dom';

import { useWheel } from './use-wheel';

import overlay from './overlay.svg';
const overlayImage = new Image();
overlayImage.src = overlay;

const Roleta = () => {
  const [items, setItems] = React.useState([]);
  const [config, setConfig] = React.useState({});

  const [prize, setPrize] = React.useState();

  const location = useLocation();
  const phone = new URLSearchParams(location.search).get('phone');

  const { wheel, wheelComponent } = useWheel({ initialProps: {} });

  const redrawWheel = () => {
    for (const key in config) {
      if (key === 'overlayImage') {
        wheel[key] = overlayImage;
      } else {
        wheel[key] = config[key];
      }
    }
    wheel.items = items;
  };

  React.useEffect(() => {
    fetch('https://api.client.dawntech.dev/blipid2024/roleta/configuration').then(r => r.json()).then((response) => {
      const config = JSON.parse(response.config);
      config.overlayImage = overlayImage;
      setConfig(config);
      setItems(JSON.parse(response.items));
    });
  }, []);

  React.useEffect(() => {
    if (Object.keys(config).length > 0 && items?.length > 0) {
      redrawWheel();
    }
  }, [config, items]);

  const getSpinIndex = () => {
    return fetch('https://api.client.dawntech.dev/blipid2024/roleta'+ (phone ? `?phone=${phone}` : '')).then(r => r.json()).then((response) => {
      const foundPrize = items.find(i => i.label === response.prize);
      
      if (response.alreadyPlayed) {
        setPrize(foundPrize);
        return -1;
      }
      setTimeout(() => {
        setPrize(foundPrize);
      }, 4000);
      return response.prizeIndex;
    });
  };

  return (
    <div className="container py-2 px-10 mx-0 min-w-full flex flex-col items-center">
      <div className="w-full h-full absolute top-0 left-0" style={{ transition: '.5s', opacity: prize ? '0.25' : '1' }}>
        {wheelComponent}
      </div>

      {
        prize ? '' : <>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full inline-flex items-center"
            style={{ zIndex: 11 }}
            onClick={async () => wheel.spinToItem(await getSpinIndex(), 4000, true, 5)}
            disabled={prize}
          >
            Girar!
          </button>
        </>
      }

      {
        prize ?
          <div style={{ position: 'relative', height: '95vh', alignContent: 'center' }}>
            <div className="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert" style={{ zIndex: 100 }}>
              <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="sr-only">Check icon</span>
              </div>
              <div className="ml-3 text-xl font-bold">{ prize.message }</div>
            </div>
          </div>
          : ''
      }

    </div>
  );
};

export default Roleta;
