import React from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import RoomIcon from '@mui/icons-material/Room';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';

import TopImageSection from '../../components/TopImageSection';

function BottomSection({ consultant }) {
  const { t } = useTranslation();

  return (
    <>
      <Container maxWidth="xl">
        <Box component={Card} borderRadius={3} mt={-18} mb={6} position={'relative'} zIndex={2} overflow="visible" data-aos="fade-in">
          <CardContent>
            <Container maxWidth="sm">
              <Grid container  spacing={{ xs: 2, sm: 32 }} direction="row" justifyContent="center" alignItems="baseline" position={'relative'} zIndex={4}>
                <Grid item xs={12} sm={6} textAlign='center' data-aos="fade-right" data-aos-delay="300">
                  <Button variant="outlined" 
                    href={t(`consultants.${consultant}.linkedin`)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{t('consultants_view.connect')}</Button>
                </Grid>
                <Grid item xs={12} sm={6} textAlign='center' data-aos="fade-right" data-aos-delay="300">
                  <Box>
                    <Link underline='none' textAlign='center' href={t(`consultants.${consultant}.github`)} target="_blank" rel="noopener noreferrer">
                      <Typography variant="h5" fontWeight="700">
                        {t(`consultants.${consultant}.publicProjects`)}
                      </Typography>
                      <Typography>
                        {t('consultants_view.projects')}
                      </Typography>
                    </Link>
                  </Box>
                </Grid>
              </Grid>

              <Avatar className="avatar" alt={t(`consultants.${consultant}.name`)} src={t(`consultants.${consultant}.profilePicture`)} sx={{ width: 150, height: 150, mx: 'auto' }} margintop={{ xs: 10, sm: -38 }}  data-aos="fade-up" data-aos-delay="300"/>
            </Container>
          </CardContent>
          <CardContent data-aos="fade-in" data-aos-delay="600">
            <Box textAlign="center">
              <Typography variant="h3">
                {t(`consultants.${consultant}.name`)}
              </Typography>

              <RoomIcon /> {t(`consultants.${consultant}.location`)}
              <br />
              <br />
              <WorkIcon /> {t(`consultants.${consultant}.position`)}
              <br />
              <SchoolIcon /> {t(`consultants.${consultant}.education`)}
              <br />
              <br />
              <Divider />

              <Box component={Container} py={5} lineHeight='2rem' fontSize='1.125rem'>
                {t(`consultants.${consultant}.summary`)}
              </Box>
            </Box>
          </CardContent>
        </Box>
      </Container>
    </>
  );
}


function ProfileView(props) {
  const { t, i18n } = useTranslation();

  const consultant = props.match.params.consultant;
  if (!i18n.exists(`consultants.${consultant}`)) {
    return <Redirect from='*' to='/' />;
  }

  return (
    <>
      <TopImageSection
        imageURI={t(`consultants.${consultant}.backgroundImage`)}
        opacity={0}
      ></TopImageSection>
      
      <BottomSection consultant={consultant} />
    </>
  );
}

export default ProfileView;
