import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

const defaultStyle = {
  position: () => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }),
  sizing: (theme) => ({
    overflowY: 'auto',
    maxHeight: '90vh',
    width: '100%',
    minWidth: 480,
    [theme.breakpoints.down('sm')]: {
      minWidth: '95%',
      maxWidth: 480
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '95%',
      maxWidth: 540,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '90%',
      maxWidth: 600
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '75%',
      maxWidth: 600
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '70%'
    }
  }),
  others: () => ({
    bgcolor: 'background.paper',
    borderRadius: '.5em',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    py: 4,
    px: 2
  }),
  shadow: () => ({
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px'
  })
};

export function HeaderModal({ open, onClose, Header, headerStyle, Body, bodyStyle }) {
  if (!Header) {
    throw new Error('HeaderModal requires a header prop');
  }

  if (!Body) {
    throw new Error('HeaderModal requires a body prop');
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        },
      }}
    >
      <Fade in={open}>
        <Box sx={[defaultStyle.position, defaultStyle.sizing, defaultStyle.shadow, { outline: 'none' }]}>
          <Box sx={[defaultStyle.shadow, defaultStyle.others, headerStyle]}>
            { Header }
          </Box>

          <Box sx={{ paddingY: '8px' }}></Box>

          <Box sx={[defaultStyle.shadow, defaultStyle.others, bodyStyle]}>
            { Body }
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export function SimpleModal({ open, onClose, style = {}, children }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        },
      }}
    >
      <Fade in={open}>
        <Box sx={[defaultStyle.position, defaultStyle.sizing, defaultStyle.shadow, defaultStyle.others, style]}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}
