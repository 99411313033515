const privacies = {
  pt: {
    backgroundImage: 'https://images.pexels.com/photos/1901388/pexels-photo-1901388.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  },
  en: {
    backgroundImage: 'https://images.pexels.com/photos/1901388/pexels-photo-1901388.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  },
  es: {
    backgroundImage: 'https://images.pexels.com/photos/1901388/pexels-photo-1901388.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
  }
};

export default privacies;
