import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  root: {
    margin: 'auto',
    borderRadius: '1rem',
    overflow: 'visible'
  },
  content: {
    padding: 24
  },
  cardMedia: {
    minHeight: 'auto',
    marginTop: '-8rem',
    clipPath: 'polygon(0px 0px, 100% 0%, 100% 84%, 0% 100%)',
    borderRadius: '1rem 1rem 0 0'
  }
}));

export const PostCard = React.memo(function PostCard({ heading, body, src }) {
  const cardStyles = useStyles();

  return (
    <Card className={cx(cardStyles.root)} data-aos={'fade-right'}>
      <CardMedia className={cx(cardStyles.cardMedia)}
        component="img"
        src={src}
      />
      <CardContent className={cardStyles.content}>
        <Typography variant="h5" fontWeight="600" gutterBottom>
          {heading}
        </Typography>
        <Typography variant="subtitle1">
          {body}
        </Typography>
      </CardContent>
    </Card>
  );
});

export default PostCard;